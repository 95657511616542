.org_menu {
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  background: #fff;
  font-family: 'Rubik', sans-serif;
}
.button {
  all: unset;
}

.org_menu_header {
  height: 3.5rem;
  padding: 0 0px 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0px 16px 24px rgba(241, 241, 241, 0.12);
}
.org_menu_header .wrapper {
  color: #4f4f4f;
  display: flex;
  align-items: center;
}

.org_menu_header .wrapper .content {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  margin-right: 12px;
}
.hr_line {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  color: #ccc;
}
.org_menu_main {
  max-height: 300px;
  overflow: auto;
}
.org_menu_main::-webkit-scrollbar {
  width: 4px;
}
.org_menu_section {
  padding: 1rem;
}
.org_menu_section span {
  margin: 9px 0 5px 0;
  color: #8e8ea9;
}

.org_menu_section .header_span {
  font-size: 12px;
}
.org_menu_section h3 {
  font-size: 14px;
  font-weight: 500;
  background-color: #d5d5d533;
  border-radius: 4px;
  padding: 4px;
  margin: 0;
}
.org_menu_section ul {
  padding: 0;
  font-size: 14px;
  list-style: none;
  color: #474747;
  margin: 5px 0 0px 0;
}
.org_menu_section ul li {
  cursor: pointer;
  padding: 0 4px 0 4px;
  margin-bottom: 1px;
  transition: 0.1s ease-out;
}
.org_menu_section ul .selected_org {
  background-color: #d5d5d533;
  font-weight: 500;
  /* padding: 4px; */
  border-radius: 4px;
}

.org_menu_section ul li:hover {
  /* background-color: #d5d5d533; */
  /* padding: 2px; */
  border-radius: 4px;
}
.org_menu_section .create_new {
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 0.25rem;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  color: #1f53d7;
}

.org_menu_section .create_new span {
  color: #1f53d7;
  display: flex;
  align-items: center;
  margin: 0;
}

.org_menu_section .user_email {
  font-size: 14px;
  color: #474747;
}
.org_menu_section .user_email span {
  font-weight: 500;
  color: #111;
}
.org_menu footer {
  box-shadow: 0px 16px 24px rgba(241, 241, 241, 0.12);
  padding: 5px 16px;
  display: flex;
  align-items: center;

  background-color: #fff;
}
.org_menu footer a {
  margin: 9px 0 5px 0;
  text-decoration: none;
  color: #8e8ea9;
  font-size: 14px;
  font-family: 'Rubik', sans-serif;
}
