$primary: #1f53d7;

@import './lib/assets/styles/var';
@import '../node_modules/bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500&display=swap');

#root {
  background-color: #fff;
  min-height: 100vh;
}
body {
  font-family: 'Rubik', sans-serif !important;
  background-color: #fff;
}
button {
  text-transform: none !important;
}

a {
  text-decoration: none;
}
fieldset {
  top: 0 !important;
}
.search-select-field:hover,
.search-select-field:focus {
  cursor: pointer !important;
}
.search-select-field:hover {
  cursor: pointer !important;
}
.search-select-field:focus-visible {
  background: transparent !important;
}
.fs-sm {
  font-size: 14px !important;
}
.fs-xs {
  font-size: 12px !important;
}
.list-style-none {
  list-style: none;
}

@media print {
  body {
    margin: 0;
    padding: 0;
  }

  @page {
    size: auto;
    margin: 20mm;
  }

  html,
  body {
    width: 100%;
    height: 100%;
  }

  div,
  p,
  table {
    page-break-inside: avoid;
  }
}
